import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import PlainHeader from "components/ui/base/PlainHeader";
import Customers from "components/ui/extended/Customers";
import ChatflowsGrid from "components/ui/base/ChatflowsGrid";
import ImageWithDescription from "components/ui/base/ImageWithDescription";

interface AIChatflowDetailProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: AIChatflowDetailProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{ withCta: false, withMenu: false }}
    >
      <SEO
        title={t("pages.ai.chatflows.3.title")}
        description={t("pages.ai.chatflows.3.description")}
        image={{
          relativePath: "pages/ai/ai-intent-upgrade-preview.jpg",
          alt: t("pages.ai.chatflows.3.title"),
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.ai.chatflows.3.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.ai.chatflows.3.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
        ]}
      />

      <PlainHeader
        title={t("pages.ai.chatflows.3.title")}
        description={t("pages.ai.chatflows.3.description")}
        titleMaxWidth={800}
        descriptionMaxWidth={700}
        image={{
          relativePath: "pages/ai/ai-mission-setup-upgrade.jpg",
          alt: "refund",
        }}
        ctaChatMessage={{
          show: true,
          text: "Try this Chatflow",
          message: "I was billed twice this month. Why did that happen?",
        }}
        ctaGetDemo={{
          show: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.ai.chatflows.3.sections.1.title")}
        description={t("pages.ai.chatflows.3.sections.1.description")}
        image={{
          relativePath: "pages/ai/ai-mission-setup-upgrade-intent.jpg",
          alt: t("pages.ai.chatflows.3.sections.1.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.ai.chatflows.3.sections.2.title")}
        description={t("pages.ai.chatflows.3.sections.2.description")}
        image={{
          relativePath: "pages/ai/ai-mission-setup-upgrade-flow.jpg",
          alt: t("pages.ai.chatflows.3.sections.2.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.ai.chatflows.3.sections.3.title")}
        description={t("pages.ai.chatflows.3.sections.3.description")}
        image={{
          relativePath: "pages/ai/ai-intent-upgrade-chat-preview.jpg",
          alt: t("pages.ai.1.title"),
          position: "right",
          fullWidth: true,
        }}
        ctaChatMessage={{
          show: true,
          text: t("pages.ai.chatflows.3.sections.3.cta"),
          message: "I've used up all my monthly interactions, what can I do?",
        }}
      />

      <Customers />

      <ChatflowsGrid
        title={t("pages.ai.chatflows.title")}
        description={t("pages.ai.chatflows.description")}
        itemPerRow={3}
        maxItems={3}
        items={[
          {
            id: 1,
            name: t("pages.ai.chatflows.3.title"),
            description: t("pages.ai.chatflows.3.description"),
            uri: "/ai/refund",
            image: "pages/ai/ai-intent-upgrade-preview.jpg",
          },
          {
            id: 2,
            name: t("pages.ai.chatflows.2.title"),
            description: t("pages.ai.chatflows.2.description"),
            uri: "/ai/trial-extension",
            image: "pages/ai/ai-intent-trial-preview.jpg",
          },
          {
            id: 3,
            name: t("pages.ai.chatflows.3.title"),
            description: t("pages.ai.chatflows.3.description"),
            uri: "/ai/upgrade-plan",
            image: "pages/ai/ai-intent-upgrade-preview.jpg",
          },
        ]}
      />
    </GlobalWrapper>
  );
};

export const AIChatflowDetailProps = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "pages/ai/ai-intent-upgrade-preview.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
