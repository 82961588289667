import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import Heading from "components/core/Typography/Heading";

const StyledChatflowCard = styled.div`
  padding: 32px 0;
`;

const ChatflowTitle = styled(Heading)`
  display: block;
  font-weight: bold;

  a {
    color: ${(props) => props.theme.titleColor};

    &:hover {
      text-decoration: underline;
    }

    ${media.md`
      font-size: 22px;
      line-height: 1.4;
    `}
  }
`;

const ChatflowDescription = styled.span`
  display: block;
`;

const CoverContainer = styled.div`
  position: relative;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  .gatsby-image-wrapper {
    border-radius: 16px;
    width: 100%;
  }
`;

const ChatflowMetadata = styled.div`
  font-size: 16px;
  padding: 4px 0;

  a {
    font-size: 16px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  * {
    color: #c4c4c4;
  }
`;

export {
  StyledChatflowCard,
  ChatflowTitle,
  ChatflowDescription,
  CoverContainer,
  ChatflowMetadata,
};
