import React from "react";
import { Col, Row } from "styled-bootstrap-grid";
import Loop from "components/core/Loop";
import {
  BoxTitle,
  GridContainer,
  StyledChatflowsGrid,
  TitleContainer,
} from "./index.style";
import { useTranslation } from "react-i18next";
import ChatflowCard from "components/ui/base/ChatflowCard";
import { Chatflow } from "app-types/template";

export interface ChatflowsGridProps {
  title: string;
  description: string;
  itemPerRow: number;
  maxItems: number;
  items: Chatflow[];
}

const ChatflowsGrid = ({
  title,
  description,
  itemPerRow,
  maxItems,
  items,
}: ChatflowsGridProps) => {
  const { t } = useTranslation();

  return (
    <StyledChatflowsGrid>
      <GridContainer>
        <Row>
          <Col sm={12}>
            <TitleContainer>
              <BoxTitle>{title}</BoxTitle>
              <div>{description}</div>
            </TitleContainer>
          </Col>
        </Row>
        <Row>
          <Loop items={items}>
            {(chatflow, idx) => (
              <Col key={`chatflow-${chatflow.id}`} sm={12} md={12 / itemPerRow}>
                <ChatflowCard chatflow={chatflow} />
              </Col>
            )}
          </Loop>
        </Row>
      </GridContainer>
    </StyledChatflowsGrid>
  );
};

export default ChatflowsGrid;
