import React from "react";
import SafeLink from "components/core/SafeLink";
import { useTranslation } from "react-i18next";
import { Chatflow } from "app-types/template";
import {
  ChatflowDescription,
  ChatflowMetadata,
  ChatflowTitle,
  CoverContainer,
  StyledChatflowCard,
} from "components/ui/base/ChatflowCard/index.style";
import StaticImg from "components/core/StaticImg";

export interface ChatflowCardProps {
  chatflow: Chatflow;
}

const ChatflowCard = ({ chatflow }: ChatflowCardProps) => {
  const { t } = useTranslation();

  return (
    <StyledChatflowCard>
      <SafeLink to={chatflow.uri}>
        <CoverContainer>
          <StaticImg alt={chatflow.image} src={chatflow.image} />
        </CoverContainer>
      </SafeLink>
      <ChatflowMetadata>
        <SafeLink to={chatflow.uri}>{<title>{chatflow.name}</title>}</SafeLink>
      </ChatflowMetadata>
      <ChatflowTitle level={3}>
        <SafeLink to={chatflow.uri}>{chatflow.name}</SafeLink>
      </ChatflowTitle>
      <ChatflowDescription>{chatflow.description}</ChatflowDescription>
    </StyledChatflowCard>
  );
};

export default ChatflowCard;
