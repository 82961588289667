import styled from "styled-components";
import { Container } from "styled-bootstrap-grid";
import { Title } from "components/core/Typography/Title";

const StyledChatflowsGrid = styled.div`
  padding: 32px 0;
`;

const GridContainer = styled(Container)`
  display: block;
  margin-top: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxTitle = styled(Title)`
  margin: 0;
`;

export { StyledChatflowsGrid, GridContainer, TitleContainer, BoxTitle };
